import { Eraser } from '@styled-icons/remix-line';
import { Eraser as EraserFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function EraserIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Eraser />
    </Icon>
  );
}

export function EraserFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <EraserFill />
    </Icon>
  );
}
